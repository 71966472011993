import contact from '@/components/contact';
import { TheMask } from 'vue-the-mask';
import search from '@/components/search'

export default{
    name: 'app',
    components:{
        contact,
        TheMask,
        search,
    },
    data(){
        return {
            scroll: null,
            showMenu: false,
            target: null,
            submenu: 0,
            dateOptions: {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric'
            },
            name: '',
            description: '',
            about: ''
        }
    },
    methods:{
        getMostVisible: function (elements) {
            let element = null,
                viewportHeight = window.innerHeight,
                max = 0;

            for (let el of elements) {
                let visiblePx = this.getVisibleHeight(el, viewportHeight);
                if (visiblePx > max) {
                    max = visiblePx;
                    element = el;
                }
            }

            return element.id;
        },
        getVisibleHeight: function (element, viewportHeight) {
            let rect = element.getBoundingClientRect(),
                height = rect.bottom - rect.top,
                visible = {
                    top: rect.top >= 0 && rect.top < viewportHeight,
                    bottom: rect.bottom > 0 && rect.bottom < viewportHeight
                },
                visiblePx = 0;

            if (visible.top && visible.bottom) {
                // Whole element is visible
                visiblePx = height;
            } else if (visible.top) {
                visiblePx = viewportHeight - rect.top;
            } else if (visible.bottom) {
                visiblePx = rect.bottom;
            } else if (height > viewportHeight && rect.top < 0) {
                let absTop = Math.abs(rect.top);

                if (absTop < height) {
                    // Part of the element is visible
                    visiblePx = height - absTop;
                }
            }

            return visiblePx;
        },
        handleScroll: function() {
            this.scroll = window.pageYOffset > 0;
            let elements = document.getElementsByClassName('index');
            this.focused = this.getMostVisible(elements);
            if(window.location.pathname !== `/${this.focused}`)
                window.history.pushState(null, this.focused, `/${this.focused}`);
            this.target = document.location.pathname.replace('/','');
        },
        scrollTo: function(){
            this.$scrollTo(`#${this.target}`);
        },
    },
    mounted() {
        this.name = process.env.VUE_APP_NAME;
        this.description = process.env.VUE_APP_DESCRIPTION;
        this.about = process.env.VUE_APP_ABOUT;

        this.handleScroll();
        window.onscroll = this.handleScroll;
        if(this.target) this.scrollTo(`#${this.target}`, 1000)
    }
}