import Vue from 'vue'
import App from './app'
import VueScrollTo from 'vue-scrollto';
import VCalendar from 'v-calendar';

Vue.config.productionTip = false
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})
Vue.use(VCalendar, {
  componentPrefix: 'vc', 
});

new Vue({
  render: function (h) { return h(App) },
}).$mount('#app')
