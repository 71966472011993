import http from '@/services/httpService';
import { TheMask } from 'vue-the-mask';
const { required, minLength } =  require('vuelidate/lib/validators');
import {validationMixin} from "vuelidate/src";

export default{
    name: 'app',
    mixins: [validationMixin],
    components:{
        TheMask
    },
    data(){
        return {
            target: null,
            submenu: 1,
            dateOptions: {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric'
            },
            hotel: {
                checkin: null,
                checkout: null,
                destinationDrop: {
                    focus: false,
                    input: '',
                    value: '',
                    isSelected: false,
                },
                rooms: 1,
                room: [
                    {
                        adult: 2,
                        children: 0,
                        child: [
                        ]
                    }
                ]
            },
            flights: {
                origin: {
                    focus: false,
                    input: '',
                    value: '',
                    isSelected: false,
                },
                flight: {
                    focus: false,
                    input: '',
                    value: '',
                    isSelected: false,
                },
                multi: [{
                    origin: {
                        focus: false,
                        input: '',
                        value: '',
                        isSelected: false,
                    },
                    flight: {
                        focus: false,
                        input: '',
                        value: '',
                        isSelected: false,
                    },
                    checkout: null,
                }],
                checkout: null,
                checkin: null,
                type: '0',
                radio: 'redondo',
                seating: {
                    adult: 1,
                    children: 0,
                    baby: 0,
                },
                seatingPlaceholder: {
                    adults: {
                        1: {
                            children: 2,
                            baby: 1,
                        },
                        2: {
                            children: 4,
                            baby: 2,
                        },
                        3:{
                            children: 6,
                            baby: 3,
                        },
                        4:{
                            children: 5,
                            baby: 4,
                        },
                        5:{
                            children: 4,
                            baby: 4,
                        },
                        6:{
                            children: 3,
                            baby: 3,
                        },
                        7:{
                            children: 2,
                            baby: 2,
                        },
                        8:{
                            children: 1,
                            baby: 1,
                        },
                        9:{
                            children: 0,
                            baby: 0,
                        },
                    }
                }
            },
            packages: {
                origin: {
                    focus: false,
                    input: '',
                    value: '',
                    isSelected: false,
                },
                destination: {
                    focus: false,
                    input: '',
                    value: '',
                    isSelected: false,
                },
                type: '0',
                checkin: null,
                checkout: null,
                rooms: 1,
                room: [
                    {
                        adult: 1,
                        children: 0,
                        child: [
                        ]
                    }
                ]
            },
            activities: {
                destination: {
                    focus: false,
                    input: '',
                    value: '',
                    isSelected: false,
                },
                checkin: null,
                checkout: null,
                room: [
                    {
                        adult: 1,
                        children: 0,
                        child: [
                        ]
                    }
                ]
            },
            packageActive: -1,
            autocomplete: [],
            timerAutocomplete: null,
            loadingItems: false,
            searchUri: '',
            name: '',
            description: '',
            about: '',
            minDate: null,
            minDateCheckout: null
        }
    },
    methods:{
        updateMenu: function(menu = 0) {
            this.submenu = menu
            const today = new Date();
            if(this.submenu === 0 || this.submenu === 3) this.minDate = today.setDate(today.getDate() + 1);
            else if(this.submenu === 2) this.minDate = today.setDate(today.getDate() + 4);
            else this.minDate = today.setDate(today.getDate() + 2);
        },
        addMulti: function(){
            this.flights.multi.push({
                origin: {
                    focus: false,
                    input: '',
                    value: '',
                    isSelected: false,
                },
                flight: {
                    focus: false,
                    input: '',
                    value: '',
                    isSelected: false,
                },
                checkout: new Date().setDate(new Date().getDate() + 4),
            });
        },
        removeMulti: function(){
            if (this.flights.multi.length > 1)
                this.flights.multi.pop();
        },
        setFocus: function(drop){
            drop.focus = true;
            document.addEventListener('click', () => this.closeSearch(drop))
        },
        closeSearch: function(drop){
            drop.focus = false;
            document.removeEventListener('click', () => this.closeSearch(drop));
        },
        generateSelected: function(placeholder, text){
            let array = [];
            for(let i=0; i< placeholder; i++) {
                if (i === 0) array.push({
                    value: i+1,
                    text: `0${i+1} ${text}`,
                });
                else array.push({
                    value: i+1,
                    text: `0${i+1} ${text}s`,
                });
            }
            return array;
        },
        changeRooms: function(){
            const value = this.hotel.rooms;
            if (value > this.hotel.room.length){
                const diff = value - this.hotel.room.length;
                for(let i=0; i<diff; i++){
                    this.hotel.room.push({
                        adult: 1,
                        children: 0,
                        child: [],
                    })
                }
                this.hotel.rooms = this.hotel.room.length;
            }else if(value < this.hotel.room.length){
                const diff = this.hotel.room.length - value;
                for (let i=0; i<diff; i++) this.hotel.room.pop();
            }
        },
        changePackages: function(){
            const value = this.packages.rooms;
            if (value > this.packages.room.length){
                const diff = value - this.packages.room.length;
                for(let i=0; i<diff; i++){
                    this.packages.room.push({
                        adult: 1,
                        children: 0,
                        child: [],
                    })
                }
                this.packages.rooms = this.packages.room.length;
            }else if(value < this.packages.room.length){
                const diff = this.packages.room.length - value;
                for (let i=0; i<diff; i++) this.packages.room.pop();
            }
        },
        changeChild: function(rooms, room){
            const value = rooms[room].children;
            if (value === 0)
                return rooms[room].child = [];
            if (value > rooms[room].child.length){
                const diff = value - rooms[room].child.length;
                for(let i=0; i<diff; i++){
                    rooms[room].child.push({
                        age: 0
                    })
                }
                rooms[room].children = rooms[room].child.length;
            }else if(value < rooms[room].child.length){
                const diff = rooms[room].child.length - value;
                for (let i=0; i<diff; i++) rooms[room].child.pop();
            }
        },
        changeChildPackages: function(room){
            const value = this.packages.room[room].children;
            if (value === 0)
                return this.packages.room[room].child = [];
            if (value > this.packages.room[room].child.length){
                const diff = value - this.packages.room[room].child.length;
                for(let i=0; i<diff; i++){
                    this.packages.room[room].child.push({
                        age: 0
                    })
                }
                this.packages.room[room].children = this.packages.room[room].child.length;
            }else if(value < this.packages.room[room].child.length){
                const diff = this.packages.room[room].child.length - value;
                for (let i=0; i<diff; i++) this.packages.room[room].child.pop();
            }
        },
        getRoomsCode: function(array){
            let params = '';
            array.forEach((room, index) => {
                let tmp = '';
                if (index !== 0)
                    tmp += '%7C';
                for(let i=0; i < room.adult; i++){
                    if (i !== 0)
                        tmp += '%2C';
                    tmp += '35';
                }
                for(let i=0; i < room.children; i++){
                    tmp += '%2C';
                    tmp += room.child[i].age;
                }
                params += tmp;
            });
            return params;
        },
        search: function(option) {
            switch(option) {
                case 'hotel':
                    const params = this.getRoomsCode(this.hotel.room);
                    const url = `${process.env.VUE_APP_SEARCH_URL}/hotel/search/${this.convertDate(this.hotel.checkin)}/${this.hotel.destinationDrop.value}/${this.convertDate(this.hotel.checkout)}/${params}?es`;
                    window.location.href = url;
                    break;
                case 'flights-sencillo':
                    let flightSimpleParams = '35';

                    if (this.flights.seating.adult > 1) for(let i = 1; i<this.flights.seating.adult; i++) flightSimpleParams += ',35';
                    if (this.flights.seating.children > 0) for(let i = 0; i<this.flights.seating.children; i++) flightSimpleParams += ',5';
                    if (this.flights.seating.baby > 0) for(let i = 0; i<this.flights.seating.baby; i++) flightSimpleParams += ',1';

                    const flightSimpleUrl = `${process.env.VUE_APP_SEARCH_URL}/flight/search/${this.convertDate(this.flights.checkin)}/${flightSimpleParams}/${this.flights.type}-0/${this.flights.origin.value}/${this.flights.flight.value}?es`;
                    window.location.href = flightSimpleUrl;
                    break;
                case 'flights-redondo':
                    let flightRedondoParams = '35';

                    if (this.flights.seating.adult > 1) for(let i = 1; i<this.flights.seating.adult; i++) flightRedondoParams += ',35';
                    if (this.flights.seating.children > 0) for(let i = 0; i<this.flights.seating.children; i++) flightRedondoParams += ',5';
                    if (this.flights.seating.baby > 0) for(let i = 0; i<this.flights.seating.baby; i++) flightRedondoParams += ',1';

                    const flightRedondoUrl = `${process.env.VUE_APP_SEARCH_URL}/flight/search/${this.convertDate(this.flights.checkin)}/${flightRedondoParams}/${this.flights.type}-0/${this.flights.origin.value}/${this.flights.flight.value}/${this.convertDate(this.flights.checkout)}?es`;
                    window.location.href = flightRedondoUrl;
                    break;
                case 'flights-multiple':
                    let flightMultipleParams = '35';

                    if (this.flights.seating.adult > 1) for(let i = 1; i<this.flights.seating.adult; i++) flightMultipleParams += ',35';
                    if (this.flights.seating.children > 0) for(let i = 0; i<this.flights.seating.children; i++) flightMultipleParams += ',5';
                    if (this.flights.seating.baby > 0) for(let i = 0; i<this.flights.seating.baby; i++) flightMultipleParams += ',1';

                    let concatDates = `${this.convertDate(this.flights.checkin)}`;
                    let concatOrigins = `${this.flights.origin.value}`;
                    let concatFlight = `${this.flights.flight.value}` ;
                    this.flights.multi.forEach( multi => {
                        console.log(this.convertDate(multi.checkout))
                        concatDates += `%7C${this.convertDate(multi.checkout)}`;
                        concatOrigins += `%7C${multi.origin.value}`;
                        concatFlight += `%7C${multi.flight.value}`;
                    });

                    const flightMultipleUrl = `${process.env.VUE_APP_SEARCH_URL}/flight/search/multi/${concatDates}/${flightMultipleParams}/${this.flights.type}-0/${concatOrigins}/${concatFlight}`;
                    window.location.href = flightMultipleUrl;
                    break;
                case 'packages':
                    const packageRooms = this.getRoomsCode(this.packages.room);
                    const packageUrl = `${process.env.VUE_APP_SEARCH_URL}/search/${this.packages.origin.value}/${this.convertDate(this.packages.checkin)}/${this.packages.destination.value}/${this.convertDate(this.packages.checkout)}/${packageRooms}/${this.packages.type}?es`;
                    window.location.href = packageUrl;
                    break;
                case 'activities':
                    const activitiesRooms = this.getRoomsCode(this.activities.room);
                    const activitiesUrl = `${process.env.VUE_APP_SEARCH_URL}/addon/search/${this.convertDate(this.activities.checkin)}/${this.activities.destination.value}/${this.convertDate(this.activities.checkout)}/${activitiesRooms}?es`;
                    window.location.href = activitiesUrl;
                    break;
            }
        },
        convertDate: function(date){
            let dateString = '';
            if (typeof(date) === 'number') date = new Date(date);
            dateString += date.getFullYear().toString();
            let tmp = date.getMonth();
            tmp ++;
            if (tmp <= 9) dateString += '0' + tmp;
            else dateString += tmp;
            tmp = date.getDate();
            if (tmp <= 9) dateString += '0' + tmp;
            else dateString += tmp;
            return dateString;
        },
        updateAutocomplete: function(drop){
            const search = drop.input;
            drop.isSelected = false;
            this.setFocus(drop);
            if (this.timerAutocomplete){
                clearTimeout(this.timerAutocomplete);
            }
            if (search.length >= 3)
                this.timerAutocomplete = setTimeout(async ()=>{
                    this.loadingItems = true;
                    this.autocomplete = [];
                    const response = await http('POST', `https://www.ibitrip.com/autocomplete/`, {
                        account: 'JAL',
                        token: '}6MFsdfgdfg3345werdfgaW9D%)uY+BW3',
                        lang: 'en',
                        code: search
                    });
                    this.autocomplete = response.data;
                    if (typeof(this.autocomplete[0]) === 'string') this.autocomplete = [];
                    else this.autocomplete = this.autocomplete.filter((item) => {
                        return (this.submenu === 0 && item.b2b_hot === '1' || this.submenu === 0 && item.b2c_hot === '1') ||
                            (this.submenu === 1 && item.b2b === '1' || this.submenu === 1 && item.b2c === '1') ||
                            (this.submenu === 2 && item.b2c_flt_hot === '1' || this.submenu === 2 && item.b2c_flt_hot === '1') ||
                            (this.submenu === 3 && item.b2b_add === '1')
                    })
                    this.loadingItems = false;
                }, 500);
            else
                this.autocomplete = [];
        },
        setLocation: function(drop, location){
            drop.input = location.value;
            drop.value = `${location.code}-${location.name}`;
            drop.isSelected = true;
        }
    },
    mounted() {
        if(window.location.search.includes('vuelos')) {
            this.updateMenu(1)
        } else if(window.location.search.includes('paquetes')) {
            this.updateMenu(2)
        } else if(window.location.search.includes('actividades')) {
            this.updateMenu(3)
        } else {
            this.updateMenu()
        }
    },
    validations(){
        const val = {
            hotel: {
                checkin: {
                    required,
                },
                checkout: {
                    required,
                },
                destinationDrop: {
                    input: {
                        required,
                        minLength: minLength(3),
                    },
                }
            },
            flights: {
                checkin: {
                    required,
                },
                origin: {
                    input: {
                        required,
                        minLength: minLength(3),
                    },
                },
                flight: {
                    input: {
                        required,
                        minLength: minLength(3),
                    },
                },
            },
            packages: {
                origin: {
                    input: {
                        required,
                        minLength: minLength(3),
                    },
                },
                destination: {
                    input: {
                        required,
                        minLength: minLength(3),
                    },
                },
                checkin: {
                    required
                },
                checkout: {
                    required
                },
            },
            activities:{
                destination: {
                    input: {
                        required,
                        minLength: minLength(3),
                    },
                },
                checkin: {
                    required
                },
                checkout: {
                    required
                },
            }
        }
        if (this.flights.radio === 'multi'){
            val.multi = {
                $each: {
                    checkout: {
                        required,
                    },
                    origin: {
                        input: {
                            required,
                            minLength: minLength(3),
                        },
                    },
                    flight: {
                        input: {
                            required,
                            minLength: minLength(3),
                        },
                    },
                }
            }
        }
        return val;
    },
    watch: {
        'hotel.checkin': function(value) {
            let date = new Date(value)
            this.minDateCheckout = date.setDate( value.getDate() + 1)
            this.hotel.checkout = null
        },
        'flights.checkin': function(value) {
            let date = new Date(value)
            this.minDateCheckout = date.setDate( value.getDate())
            this.flights.checkout = null
        },
        'packages.checkin': function(value) {
            let date = new Date(value)
            this.minDateCheckout = date.setDate( value.getDate() + 1)
            this.packages.checkout = null
        },
        'activities.checkin': function(value) {
            let date = new Date(value)
            this.minDateCheckout = date.setDate( value.getDate())
            this.activities.checkout = null
        }
    }
}
