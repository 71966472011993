export default function(method, path, data) {
    return new Promise(function(resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.open(method, path)
        xhr.setRequestHeader('Content-Type', 'application/json');
        if (path.includes('contact')) xhr.setRequestHeader('accept-language', 'es_MX');
        xhr.timeout = 5000;

        xhr.onload = function() {
            let json;
            try{
                json = JSON.parse(xhr.response);
            }catch(err){
                json = xhr.response;
            }
            if(this.status >= 200 && this.status <= 300) resolve({status: this.status, data: json});
            else reject({status: this.status, response: this.response})
        };
        xhr.onerror = function() {
            reject({status: this.status, response: this.response});
        };

        xhr.ontimeout = function() {
            reject({status: this.status, response: this.response});
        }
        xhr.send(JSON.stringify(data))
    });
}