import {validationMixin} from "vuelidate/src";
const { required, email } =  require('vuelidate/lib/validators');
import { TheMask } from 'vue-the-mask'
import xhr from '../../services/httpService'

export default {
    name: 'contact',
    mixins: [validationMixin],
    components: { TheMask },
    data() {
        return {
            user: {
                name: '',
                email: '',
                phone: '',
                subject: '',
            },
            error: false,
            sent: false,
            loading: false
        }
    },
    validations: {
        user: {
            name: { required },
            email: { required, email },
            phone: { required },
            subject: {  }
        }
    },
    methods: {
        submit: async function () {
            this.loading = true;
            let data = {
                name: this.user.name,
                email: this.user.email,
                phone: this.user.phone,
                message: this.user.subject,
                source: process.env.VUE_APP_SOURCE,
                public_key: process.env.VUE_APP_PUBLIC_KEY,
                adviser: process.env.VUE_APP_ADVISER,
            };
            try {
                let response = await xhr('POST', process.env.VUE_APP_API + '/api/index/contact', data);
                if(response.status === 200) this.sent = true;
            } catch (e) {
                this.error = true;
            } finally {
                this.loading = false
            }
        },
    }
}